<template>
<section class="section">
    <div class="container" style="padding: 20px; box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; margin-top: 5%;border-radius: .5rem;">

        <img src="https://www.construsoft.com/themes/construsoft_gavias/logo.svg" style="margin: 0 auto; margin-bottom: 48px; margin-top: 24px; display: block; width: 20%;"/>

        <b-field grouped>
            <b-field expanded label="Segment">
                <b-select expanded placeholder="Vyberte..." v-model="selectedSegment">
                    <option v-for="(option, index) in segment" :value="index+1" :key="index">{{ option }}</option>
                </b-select>
            </b-field>
            <b-field expanded label="Main Material">
                <b-select expanded placeholder="Vyberte..." v-model="selectedMaterial">
                    <option v-for="(option, index) in material" :value="index+1" :key="index">{{ option }}</option>
                </b-select>
            </b-field>
            <b-field expanded label="Nový zákazník">
                <b-select expanded placeholder="Vyberte..." v-model="selectedNovyZakaznik">
                    <option v-for="(option, index) in novyZakaznik" :value="index+1" :key="index">{{ option }}</option>
                </b-select>
            </b-field>
        </b-field>

        <b-field class="mt-5">
            <b-switch type="is-info" v-model="tcOrder">Trimble Connect objednávka?</b-switch>
        </b-field>

        <b-field class="mt-4" v-if="tcOrder" label="Typ licence">
            <b-select expanded placeholder="Vyberte..." v-model="selectedLicence">
                <option v-for="(option, index) in licence" :value="index+1" :key="index">{{ option }}</option>
            </b-select>
        </b-field>

        <b-field>
            <b-button type="is-primary" expanded @click="test()">Vygenerovat odkaz</b-button>
        </b-field>

        <p class="has-text-centered" v-if="feedback">{{feedback}}</p>

    </div>
</section>
</template>

<script>
export default {
    name: 'Gen',
    data: function(){
        return{
            selectedSegment: null, selectedMaterial: null, selectedNovyZakaznik: null, selectedLicence: null, tcOrder: false, feedback: null,
            segment: ["Engineering", "Steel", "Precast", "Cast in place", "Contractors", "Educational"],
            material: ["Steel", "Cast in place", "Precast", "Rebar", "Timber", "Aluminium", "Temporary structures", "MEP", "Other"],
            novyZakaznik: ["ano", "ne"],
            licence: ["Trimble Connect Business / Business Premium", "Trimble Connect Project - licence na projekt", "Trimble Connect Enterprise - firemní licence"],
        }
    },
    methods:{
        test(){
            if(this.selectedSegment && this.selectedMaterial && this.selectedNovyZakaznik){
                var u = window.location.origin

                if(this.tcOrder){
                    if(this.selectedLicence){
                        this.feedback = `https://objednavky.trimbleconnect.cz/tc?segment=${this.selectedSegment}&material=${this.selectedMaterial}&zakaznik=${this.selectedNovyZakaznik}&licence=${this.selectedLicence}`
                        this.copy(this.feedback)
                    }else{
                        this.feedback = "Vyplňte prosím všechny pole."
                    }
                }else{
                    this.feedback = u + `/?segment=${this.selectedSegment}&material=${this.selectedMaterial}&zakaznik=${this.selectedNovyZakaznik}`
                    this.copy(this.feedback)
                }
            }else{
                this.feedback = "Vyplňte prosím všechny pole."
            }
        },
        copy(text){
            const el = document.createElement('textarea');
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.$buefy.toast.open({ message: 'Odkaz zkopírován do schránky', type: 'is-success'})
        }
    }
}
</script>
